import React from "react";
import Truck from "../../assets/Truck.png";
import Map from "../../assets/Home-map.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchengin } from "@fortawesome/free-brands-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import "./Home.css";
import AboutUs from "../AboutUs/AboutUs";
import Service from "../Service/Service";
import ContactUs from "../ContactUs/ContactUs";
import LogoCarousel from "../Certifications/Certifications";

const Home = () => {
  return (
    <>
      <div className="home-container">
        <div className="left-side">
          <h2>Start Your Import-Export Business with Us</h2>
          <p>
            Start your import-export business with us for seamless, global trade
            solutions. Expert support and reliable services guaranteed. Let's
            grow together!
          </p>
          <form className="input-form">
            <div className="input-container">
              <FontAwesomeIcon icon={faLocationDot} className="input-icon" />
              <input type="text" placeholder="Enter Pickup location" />
            </div>
            <div className="input-container">
              <FontAwesomeIcon icon={faLocationDot} className="input-icon" />
              <input type="text" placeholder="Enter destination location" />
            </div>
            <button type="submit">
              <FontAwesomeIcon icon={faSearchengin} />
            </button>
          </form>
          <img
            src={Truck}
            alt="Descriptive Alt Text"
            className="business-image"
          />
        </div>
        <div className="right-side">
          <img src={Map} alt="Map" className="map-image" />
        </div>
      </div>
      <AboutUs />
      <Service />
      <ContactUs />
      <LogoCarousel />
    </>
  );
};

export default Home;
