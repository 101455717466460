import React from "react";
import "./Certification.css";
import logo1 from "../../assets/Apeda.png"; // Replace with your logo path
import logo2 from "../../assets/fssai.png"; // Replace with your logo path
import logo3 from "../../assets/Spices_Board.png"; // Replace with your logo path

const LogoCarousel = () => {
  return (
    <div className="logo-carousel">
      <div className="carousel-track">
        <img src={logo1} alt="Logo 1" className="carousel-logo" />
        <img src={logo2} alt="Logo 2" className="carousel-logo" />
        <img src={logo3} alt="Logo 3" className="carousel-logo" />
        <img src={logo1} alt="Logo 1" className="carousel-logo" />
        <img src={logo2} alt="Logo 2" className="carousel-logo" />
        <img src={logo3} alt="Logo 3" className="carousel-logo" />
      </div>
    </div>
  );
};

export default LogoCarousel;
