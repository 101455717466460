// import React from "react";
// import "./AboutUs.css";
// import image1 from "../../assets/Rectangle 9.png";
// import image2 from "../../assets/Rectangle 10.png";
// import image3 from "../../assets/Rectangle 11.png";
// import image4 from "../../assets/Rectangle 12.png";

// const AboutUs = () => {
//   return (
//     <div className="about-us-container">
//       <div className="about-us-left">
//         <p>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
//           vehicula urna at risus porttitor, ut cursus ex gravida. In eget odio
//           at orci efficitur varius. Vivamus sed semper justo. Nulla facilisi.
//           Curabitur in leo sit amet urna bibendum scelerisque non ac neque.
//           Suspendisse potenti.
//         </p>
//       </div>
//       <div className="about-us-right">
//         <div className="image-container">
//           <div className="image-row">
//             <img src={image1} alt="First" className="image-large" />
//           </div>
//           <div className="image-row">
//             <img src={image3} alt="Second" className="image-small" />
//             <img src={image4} alt="Third" className="image-small" />
//           </div>
//           <div className="image-row">
//             <img src={image2} alt="Fourth" className="image-large" />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AboutUs;

import React from "react";
import "./AboutUs.css";
import image1 from "../../assets/Rectangle 9.png";
import image2 from "../../assets/Rectangle 10.png";
import image3 from "../../assets/Rectangle 11.png";
import image4 from "../../assets/Rectangle 12.png";

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-left">
        <h5>About us</h5>
        <p>
          Welcome to Vijayalakshmi And Co, a global leader in the import and
          export of high-quality food products. We connect producers and
          consumers worldwide, ensuring that our clients receive only the best.
          Our mission is to bring premium global flavors to local markets with a
          focus on quality, sustainability, and customer satisfaction.
        </p>
        <p>
          Quality and reliability are at the core of Vijayalakshmi And Co. We
          work with trusted suppliers and adhere to strict quality controls to
          meet international standards. Our efficient logistics network ensures
          timely delivery, preserving the freshness of our products. Committed
          to sustainability and excellent service, we aim to positively impact
          the planet and support food-producing communities. Choose
          Vijayalakshmi And Co for expertise and exceptional service in the
          global food industry.
        </p>
      </div>
      <div className="about-us-right">
        <div className="image-container">
          <div className="image-column">
            <img src={image1} alt="First" className="image-large" />
            <img src={image3} alt="First" className="image-small" />
          </div>
          <div className="image-column">
            <img src={image4} alt="First" className="image-small" />
            <img src={image2} alt="First" className="image-large" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
