import NavBar from "./Components/NavBar/NavBar";
import Home from "./Components/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />

          {/* <Route path="/about" element={<About />} />
          <Route path="/services" element={<Service />} />
          <Route path="/contact-us" element={<Contact />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
