import React, { useState } from "react";
import "./NavBar.css";
import Logo from "../../assets/Logo.png";
import { Link } from "react-router-dom";
import Menu from "../../assets/menu.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faWhatsapp,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

function NavBar() {
  const [mobileMenu, setMobileMenu] = useState(false);

  const toggleMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const closeMobileMenu = () => {
    setMobileMenu(false);
  };

  return (
    <>
      <nav className="container dark-nav">
        <img src={Logo} className="logo" alt="" />
        <ul className={mobileMenu ? "" : "hide-mobile-menu"}>
          <li>
            <Link to="/" onClick={closeMobileMenu}>
              Home
            </Link>
          </li>

          <li>
            <Link to="/about" onClick={closeMobileMenu}>
              About us
            </Link>
          </li>
          <li>
            <Link to="/services" onClick={closeMobileMenu}>
              Services
            </Link>
          </li>
          <li>
            <Link to="/contact-us" onClick={closeMobileMenu}>
              Contact Us
            </Link>
          </li>
        </ul>
        <div className="nav-icons">
          <ul className={mobileMenu ? "" : "hide-mobile-menu"}>
            <li>
              <a
                href="https://www.facebook.com/InvoSysSoftware"
                aria-label="Facebook"
                onClick={closeMobileMenu}
              >
                <FontAwesomeIcon className="fab facebook " icon={faFacebook} />
              </a>
            </li>
            <li>
              <a href="/" aria-label="WhatsApp" onClick={closeMobileMenu}>
                <FontAwesomeIcon className="fab whatsapp " icon={faWhatsapp} />
              </a>
            </li>
            <li>
              <a href="/" aria-label="LinkedIn" onClick={closeMobileMenu}>
                <FontAwesomeIcon className="fab linkdin" icon={faLinkedinIn} />
              </a>
            </li>
          </ul>
        </div>
        <img src={Menu} alt="" onClick={toggleMenu} className="menu-icon" />
      </nav>
    </>
  );
}

export default NavBar;
