import React, { useState } from "react";
import "./ContactUs.css";
import contactImg from "../../assets/World Map.png";
import Video from "../../assets/vecteezy-airplane-route-the-pl-unscreen.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  const [result, setResult] = useState("");

  const notifySuccess = () => toast.success("File Submitted Successfully!");
  const notifySending = () => toast.warn("Submitting...");

  const handleSubmit = async (event) => {
    event.preventDefault();
    notifySending();

    const formData = new FormData(event.target);
    formData.append("access_key", "8cb58207-3b07-4f49-86ca-a9df20ceeca5");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (data.success) {
        event.target.reset();
        setResult("");
        notifySuccess();
      } else {
        setResult(data.message);
      }
    } catch (error) {
      setResult("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="contact-us-container">
      <div className="contact-heading">
        <h2>Get in Touch</h2>
        <p>Don't hesitate to reach out. We're here to help you.</p>
      </div>

      <div className="contact-card">
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div className="name-fields">
              <div className="name-field">
                <label htmlFor="firstname">First Name</label>
                <input type="text" id="firstname" name="firstname" required />
              </div>
              <div className="name-field">
                <label htmlFor="lastname">Last Name</label>
                <input type="text" id="lastname" name="lastname" required />
              </div>
            </div>

            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />

            <label htmlFor="phonenumber">Phone Number</label>
            <input type="tel" id="phonenumber" name="phonenumber" required />

            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows="4" required></textarea>

            <button type="submit">Submit</button>
            {result && <span className="error">{result}</span>}
            <ToastContainer />
          </form>
        </div>
        <div className="image-container">
          <img src={contactImg} alt="Contact Us" className="contact-img" />
          <div className="video-overlay">
            <img src={Video} alt="Airplane Route" className="contact-video" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
