import React from "react";
import "./Service.css";

const Service = () => {
  return (
    <div className="service-container">
      <section className="service-section">
        <div className="heading">
          <h2>Find the right partnership for your business</h2>
        </div>
        <div className="row">
          <div className="card">
            <h3>Service 1</h3>
            <p>
              Description of service 1. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit.
            </p>
            <p>
              Description of service 1. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit.
            </p>
            <p>
              Description of service 1. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit.
            </p>
            <p>
              Description of service 1. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit.
            </p>
          </div>
          <div className="card">
            <h3>Service 2</h3>
            <p>
              Description of service 2. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit.
            </p>
            <p>
              Description of service 2. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit.
            </p>
            <p>
              Description of service 2. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit.
            </p>
            <p>
              Description of service 2. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Service;
